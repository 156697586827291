.footer {
    position: relative;
    background-color: $color-third;
    padding: 80px 0 55px;

    &::after {
        content: url($urlShort + 'home-icons/footer.svg');
        width: 300px;
        height: 65px;
        position: absolute;
        top: -45px;
        right: 0;
        z-index: 1;
    }

    &__container {
        display: flex;
        flex-direction: column;
        row-gap: 80px;
    }

    &__content {
        display: flex;
    }

    &-logo {
        width: 100px;
        height: 100px;

        svg {
            width: 100%;
            height: 100%;
        } 
    }

    &-mairie,
    &-horaires {
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-mairie {
        margin-left: 70px;
    }

    &-horaires {
        margin-left: 30px;
    }

    &__title {
        font-size: $font-size--4;
        line-height: 1.2em;
        font-weight: $font-weight-semibold;
        color: $color-white;
        margin: 0;
    }

    &__text {
        color: $color-white;
        margin-top: 10px;
    }

    &-links {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin: 0 0 0 auto;
    }

    &__tel,
    &__contact {
        width: 270px;

        &:hover,
        &:focus {
           color: $color-text;
           background-color: $color-white; 
           border-color: $color-white;
        }
    }

    &__contact {
        &:hover,
        &:focus {
           color: $color-text;
           background-color: $color-second; 
           border-color: $color-second;
        }
    }

    &-menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 88.5px;
        row-gap: 12.5px;

        &__item {
            position: relative;

            &::after {
                content: '';
                width: 20px;
                height: 1px;
                position: absolute;
                top: 50%;
                right: -55px;
                transform: translateY(-50%);
                background-color: $color-white;
            }

            &:last-child::after {
                display: none;
            }
        }

        &__link {
            font-size: toRem(15);
            color: $color-white;
            padding: 5px 0;
            transition: $duration;

            &:hover,
            &:focus {
                color: $color-second;
            }
        }
    }
}





// 1200
@media screen and (max-width: $large) {
    .footer {        
        &-mairie,
        &-horaires {
            width: 230px;
        }
    
        &-mairie {
            margin-left: 60px;
        }
    
        &-horaires {
            margin-left: 20px;
        }
    
        &-links {
            row-gap: 15px;
        }
    
        &__tel,
        &__contact {
            width: 220px;
        }
    
        &-menu {
            column-gap: 58.5px;
    
            &__item::after {
                right: -40px;
            }
        }
    }
}



// 960
@media screen and (max-width: $medium) {
    .footer { 
        padding-bottom: 60px;

        &__container {
            row-gap: 60px;
        }

        &__content {
            flex-wrap: wrap;
            row-gap: 30px;
        } 

        &-mairie {
            width: 190px;
        }
    
        &-links {
            flex-direction: row;
            column-gap: 20px;
            margin: 0;
        }
    
        &-menu__item {
            &:nth-child(3)::after {
                display: none;
            }
        }
    }
}



// 640
@media screen and (max-width: $small) {
    .footer { 
        &__content {
            width: 100%;
            flex-direction: column;
            align-items: center;
        } 

        &-mairie,
        &-horaires {
            width: auto;
            align-items: center;
            margin: 0;
        }

        &__title,
        &__text {
            text-align: center;
        }
    
        &-links {
            justify-content: center;
            flex-wrap: wrap;
            row-gap: 10px;
            column-gap: 10px;
        }
    
        &-menu {
            justify-content: center;

            &__item {
                &:nth-child(3)::after {
                    display: block;
                }
            }
        }
    }
}