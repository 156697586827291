.breadcrumb {
    max-width: calc(100% - 70px);
    position: absolute;
    top: 0;
    left: 15px;
    transform: translateY(-62.5%);
    background-color: $color-dark;
    padding: 10px 20px 10px 0;

    &::before {
        content: '';
        width: 3000px;
        height: 100%;
        position: absolute;
        right: 100%;
        bottom: 0;
        background-color: $color-dark;
    }

    ul  {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 5px;
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 7.5px;

        &::after {
            content: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.9314 5.62567C7.85559 5.70215 7.79545 5.79299 7.75442 5.893C7.71338 5.99301 7.69226 6.10022 7.69226 6.20849C7.69226 6.31676 7.71338 6.42397 7.75442 6.52398C7.79545 6.62399 7.85559 6.71483 7.9314 6.79132L11.1042 9.99892L7.9314 13.2065C7.7785 13.3611 7.69261 13.5707 7.69261 13.7893C7.69261 14.0079 7.7785 14.2176 7.9314 14.3722C8.0843 14.5267 8.29167 14.6136 8.50789 14.6136C8.72412 14.6136 8.93149 14.5267 9.08439 14.3722L12.8377 10.5776C12.9135 10.5011 12.9737 10.4103 13.0147 10.3103C13.0558 10.2103 13.0769 10.1031 13.0769 9.99478C13.0769 9.88651 13.0558 9.7793 13.0147 9.67929C12.9737 9.57929 12.9135 9.48844 12.8377 9.41196L9.08439 5.6174C8.77365 5.30325 8.25031 5.30325 7.9314 5.62567Z' fill='%23FFF'/%3E%3C/svg%3E");
            width: 20px;
            height: 20px;
            transition: $duration;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        a,
        span {
            display: flex;
            color: $color-white;
            padding: 2.5px 0;
            transition: $duration;
        }

        a {
            &:hover,
            &:focus {
                color: $color-second;
            }
        }
    }
}





//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .breadcrumb {
            max-width: calc(100% - 60px);
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .breadcrumb {
            max-width: calc(100% - 50px);
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .breadcrumb {
            max-width: calc(100% - 30px);
        }
    }