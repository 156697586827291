//======================================================================================================
// GENERAL
//======================================================================================================
.home {
    &-title {
        display: flex;
        flex-direction: column;
        font-size: $font-size--1;
        line-height: 1.2em;
        font-weight: $font-weight-bold;
        color: $color-text;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin: 0;

        span {
            font-size: toRem(25);
            line-height: 1.1em;
            font-weight: $font-weight-thin;
            letter-spacing: 0.15em;
        }

        &--white {
            color: $color-white;
        }
    }

    &-button {
        min-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size--text-small;
        font-weight: $font-weight-semibold;
        color: $color-white;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        background-color: $color-dark;
        border: 1px solid transparent;
        border-radius: 30px;
        transition: $duration;

        svg {
            width: 30px;
            height: 30px;
            fill: $color-white;
            margin-left: -10px;
            transition: $duration;
        }

        &--main {
            color: $color-white;
            background-color: $color-main;
        }

        &--second {
            color: $color-text;
            background-color: $color-second;

            svg {
                fill: $color-text;
            }
        }

        &--white {
            color: $color-text;
            background-color: $color-white;

            svg {
                fill: $color-text;
            }
        }

        &--border {
            background-color: rgba($color-dark, .6);
            border-color: $color-white;
        }
    }
}





//======================================================================================================
// ALU
//======================================================================================================
.alu {
    width: 100%;
    position: relative;

    * {
        box-sizing: border-box;
    }

    &--video {
        img {
            display: none;
        }
    }

    .swiper-container {
        overflow: unset !important
    }

    &__item {
        width: 100% !important;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__media {
        width: calc(100% - ( (100% - $large) / 2) - 15px );
        height: 500px;
        background-color: $color-bg--image;

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__content {
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        column-gap: 175px;
    }

    &__title {
        position: relative;
        font-size: $font-size--text-medium;
        font-weight: $font-weight-semibold;
        color: $color-white;
        padding: 7.5px 20px 7.5px 0;
        margin: 0;
        transform: translateY(15px);

        &::after {
            content: '';
            width: 3000px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: $color-dark;
        }
    }

    &-pagination {
        height: 35px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 15px;
        background-color: rgba($color-dark, .8);
        padding: 0 20px;
        margin: 0 0 0 auto;
        

        &::before {
            content: '';
            width: calc(100% + 60px);
            height: 30px;
            position: absolute;
            right: 0;
            bottom: 15px;
            z-index: -2;
            background-color: rgba($color-main, .8);
        }

        &::after {
            content: '';
            width: calc(100% - 20px);
            height: 35px;
            position: absolute;
            right: 0;
            bottom: 25px;
            z-index: -1;
            background-color: rgba($color-second, .8);
        }

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            background-color: $color-white;
            opacity: 1;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-second;
            }
        }

        .swiper-pagination-bullet-active {
            background-color: $color-second;
        }
    }
}





//======================================================================================================
// ACCES RAPIDES
//======================================================================================================
.ar {
    padding: 75px 0 80px;

    &__items {
        width: 100%;
        display: flex;
        column-gap: 80px;
    }

    &__group {
        display: flex;
        column-gap: 80px;
    }

    &-item {
        width: 120px;
        height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        &:hover,
        &:focus {
            .ar-item__icon {
                fill: $color-third;
            }
        }

        &__icon {
            width: 40px;
            height: 40px;
            fill: $color-second;
            transition: $duration;
        }

        &__title {
            font-size: $font-size--5;
            line-height: 1.15em;
            font-weight: $font-weight-bold;
            color: $color-text;
            margin: 0;

            span {
                font-size: $font-size--text-small;
                font-weight: $font-weight-thin;
                letter-spacing: 0.05em;
                text-transform: uppercase;

            }
        }
    }
}





//======================================================================================================
// AGENDA
//======================================================================================================
.ag {
    position: relative;
    background-color: $color-third;
    padding: 100px 0 80px;

    &::before {
        content: '';
        width: 3000px;
        height: 100%;
        position: absolute;
        right: calc(100% - ( (100% - $large) / 2 ) - 92.5px);
        bottom: 0;
        background-color: $color-main;
        transform: skewX(-18.5deg);
        transition: $duration;
    }

    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__content {
        display: flex;
        column-gap: 30px;
    }

    &-item {
        width: 420px;

        &:hover,
        &:focus {
            .ag-item {
                &__image {
                    &::before {
                        width: 280px;
                    }

                    &::after {
                        width: 200px;
                    }

                    img {
                        transform: scale(1.1);
                    }
                }

                &__date {
                    color: $color-white;
                    background-color: $color-dark;
                }
            }
        }

        &__image {
            width: 100%;
            height: 260px;
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                width: 230px;
                height: 25px;
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: rgba($color-main, .8);
                z-index: 1;
                transition: $duration;
            }

            &::after {
                content: '';
                width: 150px;
                height: 30px;
                position: absolute;
                right: 0;
                bottom: 10px;
                background-color: rgba($color-second, .7);
                z-index: 1;
                transition: $duration;
                transition-delay: .1s;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-bg--image no-repeat center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                transition: $duration;
            }
        }

        &__content {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
        }

        &__date {
            height: 30px;
            display: flex;
            align-items: center;
            font-size: $font-size--5;
            font-weight: $font-weight-medium;
            color: $color-text;
            text-transform: uppercase;
            background-color: $color-white;
            padding: 0 20px;
            margin: -15px 0 0 auto;
            transition: $duration;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.1em;
            font-weight: $font-weight-semibold;
            color: $color-white;
            margin: 10px 0 0;
        }
    }

    &__buttons {
        position: absolute;
        top: 160px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    &__button {
        width: 220px;

        &:hover,
        &:focus {
            color: $color-text;
            background-color: $color-white;
            border-color: $color-white;
        }
    }
}





//======================================================================================================
// ACTUALITES
//======================================================================================================
.ac {
    position: relative;
    padding-top: 80px;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__pagination {
        display: flex;
        column-gap: 15px;
        margin-bottom: 10px;

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            background-color: $color-dark;
            opacity: 1;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-second;
            }
        }

        .swiper-pagination-bullet-active {
            background-color: $color-second;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        column-gap: 30px;
        margin-top: 30px;

        .swiper-container {
            width: 100%;
            overflow: visible;
        }
    }

    &-item {
        width: 370px !important;
        display: flex;
        flex-direction: column;

        &:hover,
        &:focus {
            color: $color-text;

            .ac-item {
                &__image {
                    &::before {
                        width: 200px;
                    }

                    img {
                        transform: scale(1.1);
                    }
                }

                &__category {
                    color: $color-text;
                    background-color: $color-light;
                }
            }
        }

        &__image {
            width: 100%;
            height: 235px;
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                width: 120px;
                height: 25px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: rgba($color-main, .8);
                z-index: 1;
                transition: $duration;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__category {
            height: 30px;
            display: flex;
            align-items: center;
            font-size: $font-size--5;
            line-height: 1.1em;
            font-weight: $font-weight-medium;
            color: $color-white;
            text-transform: uppercase;
            background-color: $color-dark;
            padding: 0 20px 0 10px;
            margin-top: -15px;
            transition: $duration;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.15em;
            font-weight: $font-weight-semibold;
            color: $color-text;
            margin: 10px 0 0;
        }

        &__text {
            margin-top: 7.5px;
        }
    }

    &__button {
        width: 270px;
        margin: 40px 0 0 auto;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-main;
        }
    }
}





//======================================================================================================
// DÉCOUVRIR
//======================================================================================================
.dcvr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 30px;

    > .container {
        width: 100%;
    }

    &-content {
        width: 100%;
        height: 450px;
        position: relative;
        display: flex;
        align-items: center;

        &__container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    &__image {
        height: 100%;
        position: absolute;
        top: 0;
        left: calc( (100% - $large) / 2 + 15px);
        z-index: -1;
        display: none;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(270deg, rgba(0, 0, 0, .55) 0%, rgba(0, 0, 0, .425) 42.5%, rgba(0, 0, 0, 0) 100%);
        }

        &.active {
            display: flex;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-left: 70px;
    }

    &__button {
        width: 300px;
        height: 70px;
        font-size: $font-size--text;
        border-radius: 0;
        margin: 0;

        &.active {
            background-color: $color-dark;
            border-color: $color-dark;
        }
    }

    &-infos {
        display: none;
        flex-direction: column;
        align-items: flex-end;

        &.active {
            display: flex;
        }

        &__title {
            max-width: 350px;
            font-size: $font-size--3;
            line-height: 1.15em;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-transform: uppercase;
            text-align: right;
            margin: 0;
        }

        &__text {
            max-width: 270px;
            font-weight: $font-weight-semibold;
            font-style: italic;
            color: $color-white;
            text-align: right;
            margin-top: 15px;
        }
    }

    &__link {
        width: 200px;
        margin: 50px 0 0; 

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-main;
        }
    }
}





//======================================================================================================
// KIOSQUE + SOCIAL WALL
//======================================================================================================
.kio-sowl {
    padding: 100px 0;

    &__container {
        display: flex;
        justify-content: space-between;
    }
}



.kio {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
        display: flex;
        align-items: flex-start;
        column-gap: 30px;
        margin-top: 30px;

        &__image-buttons {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &__image {
            width: 270px;
            height: 200px;
            position: relative;
            background-color: $color-bg--image;

            &::before {
                content: '';
                width: 200px;
                height: 25px;
                position: absolute;
                right: 0;
                bottom: -10px;
                z-index: 1;
                background-color: rgba($color-second, .8);
            }
    
            &::after {
                content: '';
                width: 175px;
                height: 25px;
                position: absolute;
                right: 0;
                bottom: 10px;
                background-color: rgba($color-main, .8);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) $color-bg--image no-repeat center;
            }
        }

        &__buttons {
            width: 160px;
            height: 40px;
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 15px;
            background-color: $color-dark;
            margin-top: -20px;
        }

        &__button {
            width: 30px;
            height: 30px;
            background-color: transparent;
            border: none;
            border-radius: 0;

            &:hover,
            &:focus {
                svg {
                    fill: $color-second;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $color-white;
                transition: $duration;
            }

            &--listen {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 14px;
                    height: 14px;
                }
            } 
        }

        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            margin-top: 20px;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.15em;
            font-weight: $font-weight-semibold;
            color: $color-text;
            margin: 0;
        }

        &__text {
            font-style: italic;

            span {
                text-transform: uppercase;
            }
        }
    }

    &__button {
        width: 270px;
        margin-top: 40px;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-main;
        }

        &--responsive {
            display: none;
        }
    }
}



.sowl {
    width: 570px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__socials {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }

    &__social {
        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-third;

            svg {
                fill: $color-white;
            }
        }

        span {
            margin-bottom: -3.5px;
        }

        &--desktop {
            display: block;
        }

        &--responsive {
            display: none;
        }

        &--ville {
            width: 200px;
        }

        &--mediatheque {
            width: 170px;
        }

        &--centre-culturel {
            width: 160px;
        }
    }

    &__feeds {
        margin-top: 30px;
    }

    #ff-stream-1 {
        width: 100%;
        min-height: auto !important;
        background-color: transparent;
        padding: 0;
        transition: $duration;
    
        .ff-stream-wrapper {
            width: 100%;
            height: auto !important;
            display: flex;
            column-gap: 30px;
            padding: 0;
            margin: 0;
            transition: $duration;
        }
    
        .ff-item {
            width: 270px !important;
            height: 220px !important;
            position: relative !important;
            overflow: hidden;
            transform: none !important;
            visibility: inherit !important;
            transition: $duration;

            &:hover,
            &:focus {
                .ff-label-wrapper .ff-icon {
                    background-color: $color-third;

                    &-inner:after {
                        color: $color-white;
                    }
                }
            }
    
            .picture-item__inner {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                background-color: transparent;
                box-shadow: none;
                transition: $duration;
    
                &::before {
                    display: none;
                }
            }
    
            .ff-item-cont {
                height: 100%;
            }
    
            .ff-img-holder {
                height: 100%;
                position: relative;

                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute; 
                    top: 0;
                    left: 0;
                    background: linear-gradient(360deg, rgba($color-text, 0.5) 0%, rgba($color-text, 0.25) 30.21%, rgba($color-text, 0) 100%);
                }
    
                img {
                    width: 100%;
                    height: 100%;
                    min-height: 100% !important;
                    object-fit: cover;
                    transition: $duration;
                }
            }

            .ff-label-wrapper {
                top: 20px;
                left: 20px;
                right: unset;

                .ff-icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $color-white;
                    border-radius: $border-radius--round !important;
                    transition: $duration;

                    &::before {
                        display: none;
                    }

                    &-inner {
                        width: unset;
                        top: unset;
                        left: unset;
                        right: unset;

                        &::after {
                            font-size: $font-size--text;
                            color: $color-text;
                            transition: $duration;
                        }
                    }
                }
            }
    
            .ff-item-bar {
                width: 100%;
                height: 40px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                border-top: none;
                padding: 0 20px;
    
                a,
                .ff-icon-share {
                    color: $color-white !important;
                }
            }

            .ff-share-wrapper {
                margin: 0 0 0 auto;
            }
    
            .ff-share-popup {
                right: 0;
                background-color: $color-white;
                border: none;
    
                &::after {
                    display: none;
                }

                a {
                    color: $color-text !important;
                }
            }
        }
    
        .ff-content,
        .ff-item-meta,
        .ff-icon-share:hover:after, 
        .ff-item-bar > a:hover i:after,
        .ff-loadmore-wrapper,
        .shuffle__sizer {
            display: none;
        }
    }
}









    //======================================================================================================
    // RESPONSIVE
    //======================================================================================================
    
    // 1200
    @media screen and (max-width: $large) {
        //======================================================================================================
        // GENERAL
        //======================================================================================================
        .home-title {
            font-size: $font-size--2;
            line-height: 1.25em;

            span {
                font-size: $font-size--text-medium;
            }
        }





        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &__media {
                width: calc(100% - ( (100% - $medium) / 2) - 10px );
                height: 400px;
            }
        }





        //======================================================================================================
        // ACCES RAPIDES
        //======================================================================================================
        .ar {
            &__items,
            &__group {
                column-gap: 40px;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {
            padding-top: 80px;
        
            &::before {
                right: calc(100% - ( (100% - $medium) / 2 ) - 60px);
            }
        
            &__content {
                column-gap: 20px;
            }
        
            &-item {
                width: 340px;
        
                &__image {
                    height: 205px;
                }
        
                &__date {
                    font-size: $font-size--text;
                }
        
                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.15em;
                    margin-top: 12.5px;
                }
            }
        
            &__buttons {
                top: 115px;
                row-gap: 10px;
            }
        
            &__button {
                width: 200px;
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {  
            &__pagination {
                margin-bottom: 0;
            }
            
            &__content {
                column-gap: 20px;
            }
        
            &-item {
                width: 300px !important;
        
                &__image {
                    height: 190px;
                }
        
                &__category {
                    font-size: $font-size--text;
                    line-height: 1.25em;
                }
        
                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.25em;
                }
        
                &__text {
                    font-size: $font-size--text-small;
                    line-height: 1.45em;
                    margin-top: 7.5px;
                }
            }
        
            &__button {
                width: 250px;
                margin: 30px 0 0 auto;
            }
        }





        //======================================================================================================
        // DÉCOUVRIR
        //======================================================================================================
        .dcvr {        
            &-content {
                height: 380px;
            }
        
            &__image {
                left: calc( (100% - $medium) / 2 + 10px);
            }
        
            &__buttons {
                margin-left: 55px;
            }
        }





        //======================================================================================================
        // KIOSQUE + SOCIAL WALL
        //======================================================================================================
        .kio-sowl {
            padding: 80px 0;
        }
        
        
        
        .kio {
            width: 390px;
        
            &-item {
                column-gap: 20px;
        
                &__image {
                    width: 220px;
                    height: 180px;
                }
        
                &__buttons {
                    width: 150px;
                }

                &__content {
                    margin-top: 30px;
                }
        
                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.25em;
                }
            }
        
            &__button {
                width: 220px;
                margin-top: 30px;
            }
        }



        .sowl {
            width: 460px;
        
            &__socials {
                margin-top: 30px;
            }
        
            &__social {
                &--desktop {
                    display: none;
                }

                &--responsive {
                    display: block;
                }
        
                &--ville {
                    width: 100px;
                }
        
                &--mediatheque {
                    width: 175px;
                }
        
                &--centre-culturel {
                    width: 165px;
                }
            }
        
            #ff-stream-1 {            
                .ff-stream-wrapper {
                    column-gap: 20px;
                }
            
                .ff-item {
                    width: 220px !important;
                    height: 200px !important;
        
                    .ff-label-wrapper {
                        top: 10px;
                        left: 10px;
                    }
            
                    .ff-item-bar {
                        height: 30px;
                        padding: 0 10px;
                    }
                }
            }
        }
    }





    // 960
    @media screen and (max-width: $medium) {
        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__media {
                width: 100%;
                height: 360px;
            }

            &__content {
                max-width: 100%;
                padding: 0;
            }

            &__title {
                font-size: $font-size--text;
                margin-left: calc( (100% - $small) / 2 + 10px );
            }
        }





        //======================================================================================================
        // ACCES RAPIDES
        //======================================================================================================
        .ar {
            padding-bottom: 50px;
        
            &__items,
            &__group {
                column-gap: 10px;
            }
        
            &-item {
                width: 95px;
        
                &__title {
                    font-size: toRem(15);
                    line-height: 1.33em;
        
                    span {
                        font-size: toRem(12);
                    }
                }
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {
            padding: 60px 0;
        
            &::before {
                right: calc(100% - ( (100% - $small) / 2 ) - 40px);
            }

            &__container {
                flex-direction: column;
                row-gap: 30px;
            }
        
            &-item {
                width: 300px;
        
                &__image {
                    height: 180px;
                }
        
                &__title {
                    font-size: $font-size--5;
                    line-height: 1.25em;
                }
            }
        
            &__buttons {
                position: relative;
                top: unset;
                flex-direction: row;
                column-gap: 20px;
            }
        
            &__button {
                width: 220px;
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac__button {
            width: 220px;
        }





        //======================================================================================================
        // DÉCOUVRIR
        //======================================================================================================
        .dcvr { 
            margin-top: 60px;
                           
            &__image {
                left: 0;
            }

            &-infos__title {
                max-width: 270px;
                font-size: toRem(24);
            }
        
            &__buttons {
                margin-left: 0;
            }
        }





        //======================================================================================================
        // KIOSQUE + SOCIAL WALL
        //======================================================================================================
        .kio-sowl {
            padding: 60px 0 80px;

            &__container {
                flex-direction: column;
                row-gap: 50px;
            }
        }
        
        
        
        .kio {
            width: 100%;
        
            &-item {        
                &__image {
                    width: 300px;
                    height: 240px;
                }
            }
        
            &__button {
                display: none;
                margin-top: 15px;

                &--responsive {
                    display: flex;
                }
            }
        }



        .sowl {
            width: 100%;
        
            &__socials {
                margin-top: 30px;
            }
        
            &__social {
                &--desktop {
                    display: block;
                }

                &--responsive {
                    display: none;
                }
        
                &--ville {
                    width: 230px;
                }
        
                &--mediatheque {
                    width: 190px;
                }
        
                &--centre-culturel {
                    width: 180px;
                }
            }
        
            #ff-stream-1 {                        
                .ff-item {
                    width: 300px !important;
                    height: 225px !important;
            
                    .ff-item-bar {
                        height: 40px;
                        padding: 0 20px;
                    }
                }
            }
        }
    }





    // 640
    @media screen and (max-width: $small) {
        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &__media{
                height: 310px;
            }

            &__content {
                column-gap: 25px;
            }

            &__title {
                margin-left: 5%;
            }

            &-pagination {
                min-width: auto;
                height: 30px;
                column-gap: 5px;
                padding: 0 15px;

                &::before,
                &::after {
                    display: none;
                }

                .swiper-pagination-bullet {
                    width: 10px;
                    height: 10px;
                }
            }
        }





        //======================================================================================================
        // ACCES RAPIDES
        //======================================================================================================
        .ar {
            padding-top: 40px;
        
            &__items {
                justify-content: space-between;
                flex-wrap: wrap;
                column-gap: unset;
                row-gap: 30px;
            }

            &__group {
                width: 100%;
                justify-content: space-between;
                column-gap: unset;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {        
            &::before {
                right: 90%;
            }

            &__content {
                width: 100%;
                flex-direction: column;
                row-gap: 20px;
            }
        
            &-item {
                width: 100%;
            }
        
            &__buttons {
                flex-wrap: wrap;
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {
            padding-top: 60px;

            &__header {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 30px;
            }

            &__pagination {
                column-gap: 5px;
        
                .swiper-pagination-bullet {
                    width: 10px;
                    height: 10px;
                }
            }

            &__content {
                margin-top: 15px;
            }

            &-item {
                width: 100% !important;
            }
        }





        //======================================================================================================
        // DÉCOUVRIR
        //======================================================================================================
        .dcvr {        
            &-content {
                height: 470px;
                padding: 40px 0;

                &__container {
                    height: 100%;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    row-gap: 40px;
                }
            }

            &__image::after {
                background: linear-gradient(360deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .5) 48.36%, rgba(0, 0, 0, 0) 100%);
            }

            &__buttons {
                row-gap: 10px;
            }

            &__button {
                height: 50px;
            }

            &-infos {
                max-width: 300px;
                align-items: center;

                &__title,
                &__text {
                    max-width: 100%;
                    text-align: center;
                }

                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.25em;
                }

                &__text {
                    font-size: $font-size--text-small;
                    line-height: 1.4em;
                    margin-top: 5px;
                }
            }

            &__link {
                margin-top: 20px;
            }
        }





        //======================================================================================================
        // KIOSQUE + SOCIAL WALL
        //======================================================================================================      
        .kio-item {
            flex-direction: column;

            &__image-buttons,
            &__image {
                width: 100%;
            }

            &__content {
                margin-top: 15px;
            }
        }



        .sowl {        
            &__socials {
                flex-wrap: wrap;
                justify-content: center;
                row-gap: 10px;
                column-gap: 10px;
            }
        
            &__social {        
                &--ville,
                &--mediatheque,
                &--centre-culturel {
                    width: 220px;
                }
            }

            &__feeds {
                width: 100%;
            }
        
            #ff-stream-1 {  
                width: 100%;
                 
                .ff-stream-wrapper {
                    width: 100%;
                    flex-direction: column;
                    row-gap: 20px;
                }   

                .ff-item {
                    width: 100% !important;
            
                    .ff-item-bar {
                        height: 40px;
                        padding: 0 20px;
                    }
                }
            }
        }
    }