body {
    &.admin-bar .header {
        top: 32px;
    }

    #content {
        margin-top: 125px;
        transition: $duration;
    }
}

.header {
    width: 100%;
    height: 125px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    background-color: $color-white;
    transition: $duration;

    &--fixed {
        height: 100px;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);

        .nav-main .menu__link {
            height: 100px;
        }
    }

    &__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__content {
        display: flex;
        align-items: center;
        column-gap: 135px;
    }
}


.main-logo {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 90px;
    transition: $duration;

    &::after {
        content: '';
        width: 3000px;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: $color-third;
        transform: skewX(-17.5deg);
        transition: $duration;
    }

    &:hover,
    &:focus {
        &::after {
            background-color: $color-main;
        }
    }

    svg {
        width: 246px;  
        height: 46px;
        fill: $color-white;
        margin-bottom: 12.5px;
    }

    &__title {
        display: none;
    }
}



//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    align-items: center;
    column-gap: 15px;

    #accessconfig {
        width: 40px;
        height: 40px;
    }

    &__item {
        width: 40px;
        height: 40px;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        transition: $duration;

        &:hover,
        &:focus {
            background-color: $color-third;
            border-color: $color-third;

            svg {
                color: $color-white;
            }
        }

        &--search {
            display: none;
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        svg {
            width: 100%;
            height: 100%;
            fill: $color-text;
            transition: $duration;
        }
    }
}





//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        body #content {
            margin-top: 110px;
        }

        .header {
            height: 110px;
        
            &--fixed {
                height: 90px;
        
                .nav-main .menu__link {
                    height: 90px;
                }
            }
        
            &__content {
                column-gap: 67.5px;
            }
        }
        
        
        .main-logo svg {
            width: 200px;  
            height: 40px;
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        body {
            &.admin-bar .header {
                top: unset;
            }

            #content {
                margin-top: 0;
            }
        }

        .header {
            position: relative;
            top: unset;
            left: unset;
        
            &__content {
                column-gap: 55px;
            }
        }


        .tools__item--search {
            display: flex;
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .header { 
            height: auto;

            &__container {
                flex-direction: column-reverse;
            }  

            &__content {
                width: 100%;
                height: 50px;
                position: relative;
                justify-content: space-between;
                column-gap: 0;

                &::after {
                    content: '';
                    width: 1000px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -250px;
                    z-index: -1;
                    background-color: $color-dark;

                }
            }
        }


        .main-logo {
            width: 100%;
            height: 90px;
            justify-content: center;
            padding-right: 0;

            &::after {
                width: 1000px;
                left: -250px;
                transform: unset;
            }
        }


        .tools__item {
            &:hover,
            &:focus {
                background-color: $color-light;

                svg {
                    fill: $color-text;
                }
            }

            svg {
                fill: $color-white;
            }
        } 
    }