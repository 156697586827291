.cover {
    margin-bottom: 30px;

    &__image {
        width: calc(100% - ( (100% - $large) / 2) - 15px );
        height: 500px;
        background: $color-bg--image;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__container {
        position: relative;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 65px;
    }

    &__title {
        margin: 0;
    }

    &__text {
        font-size: $font-size--text-medium;
        font-weight: $font-weight-medium;
        margin-top: 17.5px;
    }
}

body.search,
body.error404 {
    .cover__content {
        padding-top: 30px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover__image {
            width: calc(100% - ( (100% - $medium) / 2) - 10px );
            height: 400px;
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover__image {
            width: 100%;
            height: 360px;
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .cover {
            &__image {
                height: 310px;
            }

            &__content {
                padding-top: 50px;
            }
        }
    }