.nav-main {
    .menu {
        display: flex;
        column-gap: 70px;

        &__item {
            &.menu-item-has-children {
                &:hover, 
                &:focus-within {
                    .menu__link {
                        color: $color-second;
                    }

                    .submenu {
                        display: flex;
                        justify-content: center;
                    }
                }
            }

            &--profil {
                display: none;
            }
        }   
        
        &__link {
            height: 125px;
            display: flex;
            align-items: center;
            font-size: $font-size--text-medium;
            line-height: 1em;
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-transform: uppercase;
            letter-spacing: .05em;
            transition: $duration;
        }
    }



    .submenu {
        width: calc(100% - ( (100% - $large) / 2) - 15px );
        position: absolute;
        left: 0;
        z-index: 1;
        display: none;
        background: $color-dark;
        padding: 30px 0 20px;

        &__nav {
            width: 100%;
            max-width: calc($large - 30px);
            column-count: 3;
            column-gap: 30px;
            padding: 0;
            margin: 0 0 0 auto;
        }

        &__item {
            width: 100%;
            display: inline-flex;
            padding: 0;
            margin-bottom: 10px;
        }

        &__link {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            font-weight: $font-weight-semibold;
            color: $color-white;
            border: 1px solid transparent;
            padding: 10px 30px 10px 35px;
            margin-bottom: 1px;

            &::before {
                content: '\f345';
                position: absolute;
                left: 0;
                font: normal 20px/1 dashicons;
                color: rgba($color-white, .5);
                transition: all $duration;
            }

            &:hover,
            &:focus {
                color: $color-light;
                border-color: $color-light;
                padding-left: 50px;

                &::before {
                    color: $color-light;
                    left: 15px;
                }
            }

            &--grandchild {
                display: flex;
                font-weight: $font-weight;
                padding: 10px 10px 10px 55px;

                &::before {
                    left: 20px;
                }

                &:hover,
                &:focus {
                    padding-left: 55px;

                    &::before {
                        left: 20px;
                    }
                }
            }
        }
    }
    


    // For responsive
    &__button, 
    &__icon, 
    &__close, 
    .submenu__close {
        display: none;
    }
}





//=================================================================================================
// Responsive
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .nav-main {
            .menu {
                column-gap: 60px;
            }

            .menu__link {
                height: 110px;
                font-size: $font-size--5;
            }

            .submenu {
                width: calc(100% - ( (100% - $medium) / 2) - 10px );

                &__nav {
                    width: calc($medium - 20px);
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        body.js-active-navmain {
            overflow: hidden;
        }    


        .nav-main {
            position: relative;
            margin-right: 0;

            &.js-active-menu {
                .nav-main__container {
                    left: 0;
                }
            }

            &__button {
                display: flex;
                align-items: center;
                column-gap: 10px;
                font-size: $font-size--5;
                font-weight: $font-weight-semibold; 
                color: $color-text;
                letter-spacing: .05em;
                text-transform: uppercase;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                padding: 0;
                margin: 0;
                cursor: pointer;
                transition: $duration;

                &:hover, 
                &:focus {
                    color: $color-second;
                    background-color: transparent;

                    .nav-main__icon {
                        background-color: $color-second;

                        &::before,
                        &::after {
                            background-color: $color-second;
                        }
                    }
                }
            }

            &__icon {
                width: 17.5px;
                height: 2px;
                position: relative;
                top: -1px;
                display: block;
                background: $color-text;
                transition: $duration;

                &:before, 
                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    display: block;
                    background: $color-text;
                    transition: $duration;
                }

                &:before {
                    top: 6px;
                }

                &:after {
                    top: -6px;
                } 
            }

            &__container {
                width: 100%;
                height: 100vh;
                position: fixed;
                top: 0;
                left: -100vw;
                right: 100%;
                z-index: 990;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                row-gap: 30px;
                background-color: $color-dark;
                padding: 40px 0;
                transition: $duration;
            }

            &__close {
                width: 40px;
                height: 40px;
                position: relative;
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 1px solid $color-white;
                border-radius: $border-radius--round;
                margin: 0 40px 0 auto;
                padding: 10px;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-text;
                    }
                }

                svg {
                    width: 12px;
                    height: 12px;
                    fill: $color-white;
                    transition: $duration;
                }
            }

            .menu {
                width: 100%;
                flex-direction: column;
                overflow-y: auto;

                &__item {
                    padding: 0 40px;

                    &--profil {
                        position: relative;
                        display: flex;

                        &::before {
                            content: '';
                            width: 100%;
                            height: calc(100% + 1px);
                            position: absolute;
                            top: -1px;
                            left: 0;
                            z-index: -1;
                            background-color: $color-white;
                        }

                        &:hover,
                        &:focus-within {
                            .menu__link {
                                color: $color-main;
                                padding-left: 20px;
    
                                &::after {
                                    color: $color-main;
                                }
                            }
                        }

                        .menu__link {
                            color: $color-text;
                            background-color: transparent;
                            border: none;
                            border-radius: 0;
                            padding: 20px 0;
                            margin: 0;

                            &::after {
                                color: $color-text;
                            }
                        }
                    }

                    &.menu-item-has-children {
                        &:hover,
                        &:focus-within, 
                        &.js-active-submenu {
                            .menu__link {
                                color: $color-light;
                                padding-left: 20px;

                                &::after {
                                    color: $color-light;
                                }
                            }

                            .submenu {
                                justify-content: flex-start;
                            }
                        }

                        &.js-active-submenu {   
                            .submenu {
                                right: 0;
                            }
                        }
                    }
                }

                &__link {
                    width: 100%;
                    height: auto;
                    justify-content: space-between;
                    column-gap: 15px;
                    color: $color-white;
                    border-bottom: 1px solid $color-white;
                    padding: 20px 0;

                    &::after {
                        content: '\f345';
                        width: auto;
                        height: auto;
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: $duration;
                    }
                }
            }

            .submenu {
                width: 100vw;
                height: 100vh;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                top: 0;
                left: unset;
                right: 100%;
                z-index: 1;
                background-color: $color-dark;
                padding: 40px 0 60px 0;
                transition: $duration;

                &__close {
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    border: 1px solid $color-white;
                    border-radius: $border-radius--round;
                    padding: 0;
                    margin: 0 0 0 40px;
                    transition: $duration;

                    &:hover,
                    &:focus {
                        background-color: $color-white;
                        border-color: $color-white;

                        svg {
                            fill: $color-dark;
                        }
                    }

                    svg {
                        width: 32px;
                        height: 32px;
                        fill: $color-white;
                        transition: $duration;
                    }
                }

                &__nav {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 1px 40px;
                    overflow-y: auto;
                }
            }
        }

        // Admin 
        .admin-bar {
            .nav-main {
                &__container, 
                .submenu {
                    padding-top: 70px;
                }
            }
        }
    }



    @media screen and (max-width: $small) {
        .nav-main {
            &__button {
                color: $color-white;

                &:hover, 
                &:focus {
                    color: $color-second;

                    .nav-main__icon {
                        background-color: $color-second;

                        &::before,
                        &::after {
                            background-color: $color-second;
                        }
                    }
                }
            }

            &__icon {
                background: $color-white;

                &:before, 
                &:after {
                    background: $color-white;
                }
            }
        }
    }