body.admin-bar .sticky-area {
    top: calc(125px + 32px);

    &__container {
        top: calc(-125px - 32px);
    }
}

.sticky {
    &-area {
        position: fixed;
        top: 125px;
        right: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    &-profil {
        position: relative;

        &__button {
            width: 70px;
            height: 160px;
            position: relative;
            background-color: $color-main;
            border: none;
            border-radius: 0;
            padding: 0;
            margin: 0;
            overflow: hidden;
            transition: $duration;

            &::after {
                content: url($urlShort + 'home-icons/ic_profil.svg');
                width: 85px;
                height: 125px;
                position: absolute;
                top: 60px;
                left: 20px;
                opacity: .2;
            }

            &:hover,
            &:focus {
                background-color: $color-third;
                cursor: pointer;
            }
        }

        &__text {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%) rotate(-90deg);
            font-size: $font-size--4;
            font-weight: $font-weight-semibold;
            color: $color-white;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: .05em;
        }


        &__container {
            width: 300px;
            height: 100vh;
            position: absolute;
            top: -125px;
            right: -300px;
            z-index: 12;
            display: flex;
            flex-direction: column;
            row-gap: 7.5px;
            background-color: $color-main;
            box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
            padding: 50px 30px 50px 50px;
            opacity: 0;
            visibility: hidden;
            overflow: auto;
            transition: $duration;

            &::after {
                content: url($urlShort + 'home-icons/ic_profil.svg');
                width: 85px;
                height: 125px;
                position: absolute;
                top: 50px;
                left: -10px;
                z-index: -1;
                transform: scale(-1, 1);
                opacity: .2;
            }

            &.open {
                right: 0;
                opacity: 1;
                visibility: visible;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__title {
            font-size: $font-size--4;
            font-weight: $font-weight-semibold;
            color: $color-white;
            text-transform: uppercase;
            letter-spacing: .05em;
            margin: 0;
        }

        &__close {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: 1px solid $color-white;
            border-radius: $border-radius--round;
            padding: 0;
            margin: 0;
            transition: $duration;

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;
                cursor: pointer;

                svg {
                    fill: $color-text;
                }
            }

            svg {
                width: 12px;
                height: 12px;
                fill: $color-white;
                transition: $duration;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;

            li {
                width: 100%;
                display: flex;
            }
        }

        &__link {
            width: 100%;
            position: relative;
            font-family: $font-family-second;
            font-size: $font-size--5;
            font-weight: $font-weight;
            font-style: italic;
            color: $color-white;
            letter-spacing: .05em;
            padding: 17.5px 0;
            transition: $duration;

            &::after {
                content: '';
                width: 17.5px;
                height: 1px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: $color-white;
                transition: $duration;
            }

            &:hover,
            &:focus {
                color: $color-light;

                &::after {
                    width: 70px;
                    background-color: $color-light;
                }
            }
        }
    }

    &-search {
        width: 70px;
        height: 70px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-second;
        border: none;
        border-radius: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
        transition: $duration;

        &::after {
            content: url($urlShort + 'home-icons/ic_recherche_background.svg');
            width: 100px;
            height: 100px;
            position: absolute;
            top: -5px;
            left: -30px;
            opacity: .2;
        }

        &:hover,
        &:focus {
            background-color: $color-third;

            .sticky-search__icon {
                fill: $color-white !important;
            }
        }

        &--disabled {
            opacity: .2;
            pointer-events: none;
        }

        &__icon {
            width: 40px !important;
            height: 40px !important;
            fill: $color-text !important;
            transition: $duration;
        }
    }
}



// 960
@media screen and (max-width: $medium) {
    .sticky {
        &-area {
            z-index: 1000;
        }

        &-profil__button,
        &-search {
            display: none;
        }

        &-profil__container {
            width: 100vw;
            right: -100vw;
        }
    }
}